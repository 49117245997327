<template>
  <div class="container">
    <!-- 地图模块 -->
    <div class="amap-wrapper">
      <el-amap
        :plugin="plugin"
        ref="map"
        vid="amap-vue"
        :amap-manager="amapManager"
        :center="center"
        :zoom="zoom"
        :mapStyle="mapStyle"
        class="amap-demo"
        :events="events"
      >
        <!-- 手绘地图 -->
        <el-amap-ground-image
          :url="groundimage.url"
          :bounds="groundimage.bounds"
          :events="groundimage.events"
        ></el-amap-ground-image>
        <!-- 路线 -->
        <!--<el-amap-polyline
            :isOutline="true"
            :editable="polyline.editable"
            :path="polyline.path"
            strokeColor="#FFCE00"
            lineJoin="round"
            outlineColor="#ffffff"
            :geodesic="true"
            :events="polyline.events"
        ></el-amap-polyline>-->
        <!-- 标记点 -->
        <el-amap-marker
          :vid="i"
          v-for="(marker, i) in markers"
          :key="i"
          :position="marker.position"
          :icon="marker.icon"
          :label="marker.label"
          :clickable="true"
          :offset="[-12, -15]"
          :events="marker.events"
          :extData="marker"
        ></el-amap-marker>
      </el-amap>
    </div>
    <!-- 标记点信息 -->
    <point-detail
      v-if="pointDeatilFlag"
      class="point-detail"
      :pointDetail="pointDetail"
    ></point-detail>
    <router-link to="/" tag="div" class="goPoint">
      <i class="iconfont icon-fanhui"></i> 返回主路线
    </router-link>
    <van-swipe v-show="!pointDeatilFlag" class="my-swipe" show-indicators :loop="true" lazy-render :initial-swipe="activeIndex">
      <van-swipe-item>
        <!-- 详细内容 -->
        <div class="swipe-box">
          <div class="title">{{ polylineInfo.routeName }}</div>
          <div class="hint">
            途经点{{ polylineInfo.scenicCount }} 个 | {{ polylineInfo.routeLength }} 分钟
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- 路线点列表 -->
    <div class="pathDetail">
      <div
          class="item"
          v-show="!pointDeatilFlag"
          :class="pathDetailActive == i ? 'active' : ''"
          v-for="(item, i) in allPointInfo"
          :key="i"
          @click="handleMarker1(item, i)"
      >
        <img
            :src="
            'https://zhsq.jlxrmtzx.com:3012/zhly' + item.topImage
          "
            alt=""
        />
        <p>{{ item.name }}</p>
        <p>{{ item.routeOrder }}</p>
      </div>
    </div>
     <!--全局音频播放器 -->
    <my-audio class="myaudio" v-if="audioFlag"></my-audio>
    <!-- <div class="slider">
      <swiper class="swiper" dots-position="center" v-model="swiperIndex">
        <swiper-item
          class="swiper-item"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="box">
            <div class="num">{{ index + 1 }}</div>
            <img :src="item.imgsrc" alt="" />
            <div class="title">{{ item.title }}</div>
            <audio class="audio" :src="item.audiosrc" controls></audio>
          </div>
          <div class="box2">
            <div class="detail">{{ item.detail }}</div>
            <a
              href=" http://uri.amap.com/marker?position=112.563625, 37.875277&name=晋商博物院&coordinate=gaode&callnative=1"
              class="btn"
            >
              <i class="iconfont icon-daohang"></i>
              <span>去这里</span>
            </a>
          </div>
        </swiper-item>
      </swiper>
    </div> -->
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
// import { Swiper, SwiperItem } from "vux";
import PointDeatil from "../components/PointDetail.vue";
import VueAMap from "vue-amap";
import myAudio from "../components/MyAudio";
import addImgSrc from "../utils/addImgSrc";
import { mapState } from "vuex";
let amapManager = new VueAMap.AMapManager();
export default {
  components: {
    // Swiper,
    // SwiperItem,
    pointDetail: PointDeatil,
    myAudio
  },
  data() {
    let self = this;
    return {
      addImgSrc,
      pathDetailActive: -1,
      polylineInfo: {},
      allPointInfo: [],
      activeIndex: 0,
      amapManager,
      zoom: 9,
      center: [112.142597,38.386603], // 地图中心点
      origin: [112.142597,38.386603], // 手绘地图原点
      // 地图的事件
      events: {
        click: (e) => {
          this.handleMapClick(e);
        },
      },
      // 手绘地图图层信息
      groundimage: {
        url: require('@/assets/images/qy.png'),
        bounds: [
          // [112.200686, 37.653337], //左下角
          // [112.600734, 38.022378] //右上角
          [111.704041,38.138361], //左下角
          [112.325487,38.694969] //右上角
        ],
        events: {
          click() {
            alert("click groundimage");
          },
        },
      },
      //回到当前定位点
      plugin: [
        {
          timeout: 100, //超过10秒后停止定位，默认：无穷大
          panToLocation: false, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: false, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          pName: "Geolocation",
          showButton: false,
          events: {
            click() {
              console.log(111);
            },
            init(o) {
              // o 是高德地图定位插件实例
              if (!navigator.geolocation) {
                alert("不能获取到位置");
              }
              o.getCurrentPosition((status, result) => {
                console.log(status, result);
                if (result || result.position) {
                  self.address = result.formattedAddress;
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  // self.center = [self.lng, self.lat];
                  self.loaded = true;
                  self.$nextTick();
                }
              });
            },
          },
        },
      ],
      mapStyle: "fresh",
      //决定返回景区的显示与否
      gobackFlag: false,
      // 折线数据
      polyline: {
        path: [
          // [112.563062, 37.873333],
          // [112.563223, 37.875772],
          // [112.56333, 37.877796],
        ],
        events: {
          click(e) {
            alert("click polyline");
            console.log(e);
          },
          end: (e) => {
            let newPath = e.target
                .getPath()
                .map((point) => [point.lng, point.lat]);
            console.log(newPath);
          },
        },
        editable: false,
      },
      //点标记信息
      markers: [],
      // 轮播信息
      swiperList: [],
      swiperIndex: 0,
      // 决定标记点详情的显示与否
      pointDeatilFlag: false,
      pointDetail:{}
    };
  },
  methods: {
    async getAccessToken () {
      let url = location.href.split('#')[0]
      // let url = 'https://xishanapi.chwhyun.com/';
      const { data: res } = await this.$axios.post(
          `open/wx/getSignature?url=${url}`
      )
      if (res.code == 200) {
        console.log(res.data, '!!!')
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appid, // 必填，公众号的唯一标识
          timestamp: +res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.noncestr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            "getLocation",
            "openLocation",
            "onVoicePlayEnd",
            "playVoice",
            "pauseVoice",
            "stopVoice",
            "checkJsApi",
          ], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          wx.checkJsApi({
            jsApiList: ["openLocation"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
              console.log(res);
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            },
          });
          // wx.getLocation({
          //   type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          //   success: function (res) {

          //   },
          // });
        });
      }
    },
    // 点击地图的事件
    handleMapClick(e) {
      console.log("123456", e);
      this.pointDeatilFlag = false;
    },
    // 点击标记点的事件
    handleMarker(e) {
      const data = e.target.w.extData;
      this.getPointDetail(data.id);
      this.center = data.position;
      // for (let i = 0; i < this.markers.length; i++) {
      //   if (!Object.is(data.id, this.markers[i].id)) {
      //     // this.markers[i].icon = this.markers[i].icon2;
      //   }
      // }
    },
    handleMarker1(obj, i) {
      console.log(obj);
      const { latitude, longitude, id } = obj;
      this.center = [longitude, latitude];
      this.zoom = 14;
      this.getPointDetail(id);
      this.pathDetailActive = i;
    },
    /**
     * 获取单个点的具体信息
     */
    async getPointDetail(id) {
      this.pointDeatilFlag = false;
      const { data: res } = await this.$axios.get("/open/backend/attractions/" + id);
      console.log(res, 555);
      if (res.code == 200) {
        let obj = {};
        obj.id = res.data.id;
        obj.detail = res.data.descr;
        obj.title = res.data.name;
        obj.icon = addImgSrc('/jingdianicon.png')
        obj.imgsrc = 'https://zhsq.jlxrmtzx.com:3012/zhly' + res.data.topImage
        if (res.data.audio) {
          obj.audiosrc = 'https://zhsq.jlxrmtzx.com:3012/zhly' + res.data.audio
        } else {
          obj.audiosrc = null
        }
        obj.duration = res.data.audioTime;
        obj.latitude = res.data.latitude
        obj.longitude = res.data.longitude
        // obj.flag = true;
        this.pointDetail = obj;
      }
      console.log(this.pointDetail);
    },
    /**
     * 获取所有的点信息
     */
    async getAllPointInfo() {
      const id = this.$route.params.id;
      const { data: res } = await this.$axios.get(
          "/open/backend/route/routeWithAttractions/" + id
      );
      this.polylineInfo = {
        id: res.data.id,
        routeName: res.data.routeName,
        routeLength: res.data.routeLength,
        scenicCount: res.data.scenicCount
      }
      this.allPointInfo = res.data.attractionsVO;
      let points = []
      for (let i = 0; i < res.data.attractionsVO.length; i++) {
        points.push({
          id: res.data.attractionsVO[i].id,
          routeOrder: res.data.attractionsVO[i].routeOrder,
          latitude: res.data.attractionsVO[i].latitude,
          longitude: res.data.attractionsVO[i].longitude
        })
      }
      this.changePolyline(points);
      this.changeMarkers(points);
      this.changeSwiperList(points);
    },
    /**
     * 把数据处理成折线数据
     */
    changePolyline(data) {

      let polyArr = [];
      data.forEach((item) => {
        let iArr = [item.longitude, item.latitude];
        polyArr.push(iArr);
      });
      this.polyline.path = polyArr;
    },
    /**
     * 把数据处理成点标记信息
     */
    changeMarkers(data) {
      let arr = [];
      data.forEach((item) => {
        console.log(item, 66666)
        let obj = {};
        obj.id = item.id;
        obj.label = {
          content: item.routeOrder,
          offset: [5, 3]
        }
        obj.routeOrder = item.routeOrder
        obj.position = [item.longitude, item.latitude];
        obj.icon = addImgSrc('/jingdianicon.png')
        // obj.icon = addImgSrc(item.icon);
        // obj.icon1 = addImgSrc(item.icon1);
        // obj.icon2 = addImgSrc(item.icon);
        obj.events = {
          click: (e) => {
            this.handleMarker(e);
          },
        };
        arr.push(obj);
      });
      this.markers = arr;
    },
    /**
     * 将数据处理成轮播图
     */
    changeSwiperList(data) {
      console.log(data);
      let arr = [];
      data.forEach((item) => {
        let obj = {};
        obj.id = item.id;
        obj.imgsrc = addImgSrc(item.picture);
        obj.title = item.name;
        obj.audiosrc = addImgSrc(item.audio);
        obj.detail = item.content;
        obj.flag = true;
        arr.push(obj);
      });
      this.swiperList = arr;
    },
  },
  created() {
    this.getAccessToken();
    this.getAllPointInfo();
  },
  computed: {
    ...mapState(["defaultIndex","audioFlag"]),
  },
  watch: {
    pointDetail: {
      handler(newVal) {
        this.pointDetail = newVal;
        this.pointDeatilFlag = true;
      },
      deep: true,
    },
  },
};
</script>


<style scoped lang="less">
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  .myaudio {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 20px;
    width: 80%;
    height: 60px;
    background-color: #f1f3f4;
  }
}
.amap-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.point {
  position: absolute;
  left: 20px;
  bottom: 60px;
  z-index: 99;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  i {
    font-size: 20px;
  }
}
.point-detail {
  position: absolute;
  width: 95vw;
  left: 50%;
  transform: translate(-50%);
  bottom: 10px;
  z-index: 99;
  border-radius: 15px;
}
.goPoint {
  position: absolute;
  left: 10px;
  bottom: 180px;
  width: 30%;
  height: 40px;
  background-color: #12dba5;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 14px;
  i {
    font-size: 18px;
  }
}
.my-swipe{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25vh;
  background-color: #fff;
  .van-swipe-item {
    height: 100%;
    width: 100%;
    position: relative;
    .swipe-box {
      position: absolute;
      left: 10px;
      top: 18px;
      width: 100%;
      height: 80%;
      .title {
        color: #333;
        font-size: 14px;
      }
      .hint {
        color: #666;
        font-size: 12px;
      }
    }
  }
}
.pathDetail {
  position: absolute;
  left: 10px;
  bottom: 8px;
  width: 100%;
  height: 90px;
  margin: 5px auto;
  box-sizing: border-box;
  overflow: scroll;
  display: flex;
  flex-wrap: nowrap;
  .item {
    width: 50px;
    margin-right: 20px;
    margin-left: 5px;
    text-align: center;
    margin-top: 8px;
    transition: all 0.3s;
    img {
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid #12dba5;
    }
    p {
      font-size: 12px;
      color: #666;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }
  .item.active {
    transform: scale(1.1);
  }
}
// .slider {
//   width: 96%;
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%);
//   bottom: 10px;
//   z-index: 99;
//   border-radius: 5px;
//   .swiper {
//     width: 100%;
//   }
//   .swiper-item {
//     width: 98%;
//     border-radius: 5px;
//     overflow: hidden;
//     background-color: #fff;
//     .box {
//       display: flex;
//       justify-content: space-between;
//       box-sizing: border-box;
//       margin-top: 20px;
//       width: 96%;
//     }
//     .num {
//       width: 18px;
//       height: 20px;
//       background-color: #b6851a;
//       color: #fff;
//       border-top-right-radius: 50%;
//       border-bottom-right-radius: 50%;
//       text-align: center;
//     }
//     img {
//       width: 60px;
//       height: 60px;
//       border-radius: 5px;
//     }
//     .title {
//       font-weight: bold;
//     }
//     .audio {
//       height: 40px;
//       width: 50%;
//     }
//     .box2 {
//       margin: 10px auto;
//       width: 90%;
//       border-top: 1px solid #f5f5f5;
//       padding-top: 10px;
//       display: flex;
//       justify-content: space-between;
//       .detail {
//         width: 70%;
//         color: #999;
//         font-size: 12px;
//         display: -webkit-box;
//         -webkit-box-orient: vertical;
//         -webkit-line-clamp: 3; //需要控制的文本行数
//         overflow: hidden;
//       }
//       .btn {
//         margin-top: 10px;
//         width: 25%;
//         height: 40px;
//         background-color: #12dba5;
//         border-radius: 15px;
//         line-height: 40px;
//         text-align: center;
//         color: #fff;
//         span {
//           font-size: 12px;
//         }
//       }
//     }
//   }
// }
</style>
<style>
.amap-marker-label {
//color: #fff;
//background-color: #000;
//opacity: 0.5;
  color: #000;
  background: transparent;
  border: 0 none;
//border-radius: 50%;
}
</style>
